<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div v-if="formtype == 'view'" class="col text-right">
                            <base-button size="sm" type="default" @click="tracking">{{ tt('tracking') }}</base-button>
                        </div>
                        <div v-if="formtype == 'edit'" class="col text-right">
                            <base-button size="sm" type="default" @click="create('b4')">{{ tt('add_new') }}</base-button>
                            <!-- <base-button size="sm" type="default" @click="create(mcrInformation.type)">{{ tt('add_new') }}</base-button> -->
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType != 'b6'" :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a3' || mcrType == 'b4'" :label="tt('note')" :prop="tt('note')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a4'" :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.reason }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_from')" :prop="tt('consolidate_from')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_from }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_to')" :prop="tt('consolidate_to')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_to }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('detail') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('edit') }}</el-dropdown-item> -->
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'b4'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>
        
        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>

                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        
        <modal :show.sync="form.b4.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{tt('add_new')}}</h5>
            </template>

            <div class="card">
                <div class="card-header bg-primary">
                    <h4 class="text-white">{{ tt('extend_material') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('item_type')" rules="required">
                                <el-autocomplete
                                class="inline-input col-md-12"
                                v-model="inputDraftListB4.material_number"
                                :fetch-suggestions="querySearchMatNum"
                                :placeholder="tt('choose_material_number')"
                                :trigger-on-focus="false"
                                @select="handleSelectMaterial"
                                size="large"
                                ></el-autocomplete>
                                <!--                                 
                                <el-select class="select-danger" v-model="inputDraftListB4.material_number" :placeholder="tt('choose_item_type')">
                                    <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
                                </el-select> 
                                -->
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('bom_or_non_bom')" rules="required">
                                <el-select class="select-danger" v-model="draftList.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                    <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                </el-select>
                            </base-input> -->
                            <base-input :name="tt('description')" :placeholder="tt('description')" v-model="inputDraftListB4.description" rules="required"></base-input>
                        </div>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('manufacturer_part_number')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpnB4">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <table class="table align-items-center table-flush table-bordered">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('manufacturer_code')}}</th>
                                <th class="text-white">{{tt('mpn')}}</th>
                                <th class="text-white">{{tt('type')}}</th>
                                <th class="text-white">{{tt('note')}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(mpn, key) in manufacturePartNumberB4.manufacture_code" v-bind:key="mpn.manufacture_code">
                                <td>
                                    <select class="form-control-sm" 
                                    :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                    v-model="manufacturePartNumberB4.manufacture_code[key]">
                                        <option value="">Choose Manufacture</option>
                                        <option :value="m.manufacture_code" v-for="m in manufacture" v-bind:key="m.id">
                                            {{m.manufacture_name}}</option>
                                    </select>
                                </td>
                                <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')"
                                        :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                        v-model="manufacturePartNumberB4.mpn[key]" rules="required"></td>
                                <td>
                                    <select class="form-control-sm" 
                                    :disabled="manufacturePartNumberB4.hapus[key]=== '1'" 
                                    v-model="manufacturePartNumberB4.manufacture_type[key]">
                                        <option value="">Choose Type</option>
                                        <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                    </select>
                                </td>
                                <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')"
                                        v-model="manufacturePartNumberB4.manufacture_note[key]" rules="required"></td>
                                <td><i v-if="manufacturePartNumberB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removeMpnB4(key)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('equipment_&_quantity_install')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addEqiB4">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th class="text-white">{{tt('equipment_code')}}</th>
                                    <th class="text-white">{{tt('quantity_install')}}</th>
                                    <th class="text-white">{{tt('drawing_number')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(eqi, key) in equipmentQuantityInstallB4.equipment_code" v-bind:key="eqi.equipment_code">
                                    <td>
                                        <select class="form-control-sm" 
                                        :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'" v-model="equipmentQuantityInstallB4.plant_code[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">
                                                {{pc.description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" 
                                        v-model="equipmentQuantityInstallB4.equipment_code[key]"
                                        :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'">
                                            <option value="">Choose Equipment Code</option>
                                            <option :value="ec.equipment_code" v-for="ec in equipmentCode"
                                                v-bind:key="ec.equipment_code">{{ec.equipment_name}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('qty_install')"
                                            :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallB4.qty_installed[key]"
                                            :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'"
                                            rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('drawing_number')"
                                            :placeholder="tt('drawing_number')" v-model="equipmentQuantityInstallB4.drawing_number[key]"
                                            :disabled="equipmentQuantityInstallB4.hapus[key]=== '1'"
                                            rules="required"></td>
                                    <td><i v-if="equipmentQuantityInstallB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removeEqiB4(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('plant')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addPlantB4">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pl, key) in plantLevelB4.plant" v-bind:key="pl.plant">
                                        <td>
                                            <select class="form-control-sm" 
                                            :disabled="plantLevelB4.hapus[key]=== '1'"
                                            v-model="plantLevelB4.plant[key]">
                                                <option value="">Choose Plant</option>
                                                <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.plant_code">
                                                    {{pc.description}}</option>
                                            </select>
                                        </td>
                                        <td><i v-if="plantLevelB4.hapus[key]=== '0'" class="fa fa-trash text-danger" @click="removePlantB4(key)"></i></td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('sales')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpn">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('sales_org')}}</th>
                                    <th class="text-white">{{tt('distr_channel')}}</th>
                                    <th class="text-white">{{tt('division')}}</th>
                                    <th class="text-white">{{tt('tax_cat')}}</th>
                                    <th class="text-white">{{tt('tax_class')}}</th>
                                    <th class="text-white">{{tt('gen_item_cat_grp')}}</th>
                                    <th class="text-white">{{tt('account_assign_grp')}}</th>
                                    <th class="text-white">{{tt('item_cat_grp')}}</th>
                                    <th class="text-white">{{tt('trans_group')}}</th>
                                    <th class="text-white">{{tt('loading_group')}}</th>
                                    <th class="text-white">{{tt('profit_center')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(s, key) in salesB4.sales_org" v-bind:key="s.sales_org">
                                    <td>
                                        <select class="form-control form-control-sm" v-model="salesB4.sales_org[key]">
                                            <option value="">Choose Sales Organization</option>
                                            <option :value="so.sales_org_code" v-for="so in salesOrganization"
                                                v-bind:key="so.sales_org_code">{{so.sales_org_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.distr_channel[key]">
                                            <option value="">Choose Distribution Channel</option>
                                            <option :value="dc.distr_channel_code" v-for="dc in distributionChannel"
                                                v-bind:key="dc.distr_channel_code">{{dc.distr_channel_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.division[key]">
                                            <option value="">Choose Division</option>
                                            <option :value="d.division_code" v-for="d in division" v-bind:key="d.division_code">
                                                {{d.division_desc}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('tax_cat')" :placeholder="tt('tax_cat')"
                                            v-model="salesB4.tax_cat[key]" rules="required"></td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.tax_class[key]">
                                            <option value="">Choose Tax Classification</option>
                                            <option :value="tc.tax_class_code" v-for="tc in taxClassification"
                                                v-bind:key="tc.tax_class_code">{{tc.tax_class_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.gen_item_cat_grp[key]">
                                            <option value="">Choose Item Category Group</option>
                                            <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup"
                                                v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.account_assign_grp[key]">
                                            <option value="">Choose Account Assignment Group</option>
                                            <option :value="aag.acc_ass_group_code" v-for="aag in accountAssignmentGroup"
                                                v-bind:key="aag.acc_ass_group_code">{{aag.acc_ass_group_code}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.item_cat_grp[key]">
                                            <option value="">Choose Item Category Group</option>
                                            <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup"
                                                v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.trans_group[key]">
                                            <option value="">Choose Transportation Group</option>
                                            <option :value="tg.trans_group_code" v-for="tg in transportationGroup"
                                                v-bind:key="tg.trans_group_code">{{tg.trans_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.loading_group[key]">
                                            <option value="">Choose Loading Group</option>
                                            <option :value="lg.loading_group_code" v-for="lg in loadingGroup"
                                                v-bind:key="lg.loading_group_code">{{lg.loading_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB4.profit_center[key]">
                                            <option value="">Choose Profit Center</option>
                                            <option :value="pc.profit_center_code" v-for="pc in profitCenter"
                                                v-bind:key="pc.profit_center_code">{{pc.profit_center_description}}</option>
                                        </select>
                                    </td>
                                    <td><i class="fa fa-trash text-danger" @click="removeSB3(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('mrp')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpn">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('abc_indicator')}}</th>
                                    <th class="text-white">{{tt('mrp_type')}}</th>
                                    <th class="text-white">{{tt('mrp_controller')}}</th>
                                    <th class="text-white">{{tt('lot_size')}}</th>
                                    <th class="text-white">{{tt('min')}}</th>
                                    <th class="text-white">{{tt('max')}}</th>
                                    <th class="text-white">{{tt('procurement_type')}}</th>
                                    <th class="text-white">{{tt('special_proc')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mpn, key) in manufacturePartNumber.manufacturer_code" v-bind:key="mpn.manufacturer_code">
                                    <td><input class="form-control form-control-sm" :name="tt('manufacturer_code')" :placeholder="tt('manufacturer_code')" v-model="manufacturePartNumber.manufacturer_code[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumber.mpn[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('type')" :placeholder="tt('type')" v-model="manufacturePartNumber.type[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><i class="fa fa-trash text-danger" @click="removeMpn(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('storage_location')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpn">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <table class="table align-items-center table-flush table-bordered">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('manufacturer_code')}}</th>
                                <th class="text-white">{{tt('mpn')}}</th>
                                <th class="text-white">{{tt('type')}}</th>
                                <th class="text-white">{{tt('note')}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(mpn, key) in manufacturePartNumber.manufacturer_code" v-bind:key="mpn.manufacturer_code">
                                <td><input class="form-control form-control-sm" :name="tt('manufacturer_code')" :placeholder="tt('manufacturer_code')" v-model="manufacturePartNumber.manufacturer_code[key]" rules="required"></td>
                                <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumber.mpn[key]" rules="required"></td>
                                <td><input class="form-control form-control-sm" :name="tt('type')" :placeholder="tt('type')" v-model="manufacturePartNumber.type[key]" rules="required"></td>
                                <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                <td><i class="fa fa-trash text-danger" @click="removeMpn(key)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('accounting')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpn">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('valuation_class')}}</th>
                                    <th class="text-white">{{tt('valuation_category')}}</th>
                                    <th class="text-white">{{tt('valuation_type')}}</th>
                                    <th class="text-white">{{tt('price_control')}}</th>
                                    <th class="text-white">{{tt('price_determination')}}</th>
                                    <th class="text-white">{{tt('price_unit')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mpn, key) in manufacturePartNumber.manufacturer_code" v-bind:key="mpn.manufacturer_code">
                                    <td><input class="form-control form-control-sm" :name="tt('manufacturer_code')" :placeholder="tt('manufacturer_code')" v-model="manufacturePartNumber.manufacturer_code[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumber.mpn[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('type')" :placeholder="tt('type')" v-model="manufacturePartNumber.type[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.note[key]" rules="required"></td>
                                    <td><i class="fa fa-trash text-danger" @click="removeMpn(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    <label class="form-control-label mt-4">{{ tt('note') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('note')" rules="required">
                        <textarea class="form-control" rows="5"></textarea>
                    </base-input>
                </div>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary">{{ tt('add') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import plantCode from '@/services/master/plantCode.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    
    

    export default {        
        data() {
            return { 
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype:window.location.hash.split('/')[5],
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                },
                btnSend: {
                    onLoading: false
                },         
                formMcrInformation: {
                    show: false
                },                
                form: {
                    b4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                },
                formDetail: {
                    show: false
                },
                mcrType: 'b4',  
                inputDraftListB4: {
                    material_number: '',
                    description: '',
                    note: '',
                },
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                valuesmat:[],
                mcrItem: [],
                plantCode: {},
                equipmentCode: {},
                drawingMaster: {},
                manufacture: {},
                manrefType: {},
                salesOrganization: {},
                
                
                equipmentQty4:[],
                manufPart4:[],
                plantList4:[],
                
                manufacturePartNumberB4: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: [],
                    hapus:[],
                },
                equipmentQuantityInstallB4: {
                    plant_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                    hapus:[],
                    
                },
                plantLevelB4: {
                    plant: [],
                    hapus:[],
                },
                salesB4: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                total_mcr_item: '',
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            
        },
        methods: {
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject; 
                    context.total_mcr_item = response.data.data.mcr_item.length;                    
                })
                .call()
            },
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.get({per_page: 'none'})).onSuccess(function(response) {    
                    //context.materialNumber = response.data.data;
                    var values = [];                                
                    response.data.data.forEach(function(value, j) {
                        values[j] = {
                            'value': value['material_number']
                        }
                    });
                    context.valuesmat = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        //context.materialNumber = [];
                        context.valuesmat = [];
                    }
                })
                .call()
            },
            
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.plantCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manufacture = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },


            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;
                this.form.b4.show = true;
                
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            querySearchMatNum(queryString, cb) {
                let context = this;
                if (queryString.length > 2) {
                    var links = context.valuesmat;
                    //console.log(links)
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            
            createFilter(queryString) {
                return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },

            handleSelectMaterial(item) {
                // this.inputDraftListB3.prc_inc = item.link;
                // this.changeStandardItemNameB2();
                this.getMaterialSpek();
                //console.log(this.inputDraftListB4.material_number);
            },
            
            getMaterialSpek() {
                let context = this;        
                var datanya = {};       
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListB4.material_number})).onSuccess(function(response) {    
                    datanya = response.data.data;
                    context.equipmentQty4 = datanya.part_equipment_code
                    var equip = {
                        plant_code: [],
                        equipment_code: [],
                        qty_installed: [],
                        drawing_number: [],
                        hapus: [],
                    }
                    context.equipmentQty4.forEach(function(value, j) {
                        equip.plant_code[j] = value['plant_code']
                        equip.equipment_code[j] = value['equipment_code']
                        equip.qty_installed[j] = value['qty_installed']
                        equip.drawing_number[j] = value['drawing_number']
                        equip.hapus[j] = '1'
                    });
                    
                    context.equipmentQuantityInstallB4 = equip;
                    
                    //manufacture
                    context.manufPart4 = datanya.part_manufacture_ref
                    console.log(context.manufPart4); 
                    var manuf = {
                        manufacture_code: [],
                        mpn: [],
                        manufacture_type: [],
                        manufacture_note: [],
                        hapus:[],
                    }
                    
                    context.manufPart4.forEach(function(val, m) {
                        manuf.manufacture_code[m] = val['mancode']
                        manuf.mpn[m] = val['material_number_mpn']
                        manuf.manufacture_type[m] = val['type']
                        manuf.manufacture_note[m] = val['note']
                        manuf.hapus[m] = '1'
                    });
                    
                    context.manufacturePartNumberB4 = manuf;
                    
                    //Plantcode
                    context.plantList4 = datanya.part_plant
                    console.log(context.plantList4); 
                    var pelan = {
                        plant: [],
                        hapus:[],
                    }
                    
                    context.plantList4.forEach(function(val, m) {
                        pelan.plant[m] = val['plant_code']
                        pelan.hapus[m] = '1'
                    });
                    
                    context.plantLevelB4 = pelan;
                    
                   


                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            
            create(key) {
                this.getMaterialNumber()
                this.getPlantCode()
                this.getEquipmentCode()
                this.getManufacture()
                this.getManrefType()
                this.form.b4.show = true;
            },
            
            addMpn() {
            },

            addMpnB4() {
                if (this.manufacturePartNumberB4.manufacture_code.length == 0) {
                    this.manufacturePartNumberB4.manufacture_code.push('')
                    this.manufacturePartNumberB4.mpn.push('')
                    this.manufacturePartNumberB4.manufacture_type.push('')
                    this.manufacturePartNumberB4.manufacture_note.push('')
                } else if (this.manufacturePartNumberB4.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB4.manufacture_code[this.manufacturePartNumberB4.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB4.mpn[this.manufacturePartNumberB4.mpn.length-1] != '' &&
                        this.manufacturePartNumberB4.manufacture_type[this.manufacturePartNumberB4.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB4.manufacture_note[this.manufacturePartNumberB4.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB4.manufacture_code.push('')
                        this.manufacturePartNumberB4.mpn.push('')
                        this.manufacturePartNumberB4.manufacture_type.push('')
                        this.manufacturePartNumberB4.manufacture_note.push('')
                        this.manufacturePartNumberB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnB4(key) {
                this.manufacturePartNumberB4.manufacture_code.splice(key, 1)
                this.manufacturePartNumberB4.mpn.splice(key, 1)
                this.manufacturePartNumberB4.manufacture_type.splice(key, 1)
                this.manufacturePartNumberB4.manufacture_note.splice(key, 1)
                this.manufacturePartNumberB4.hapus.splice(key, 1)
            },
            addEqiB4() {
                if (this.equipmentQuantityInstallB4.plant_code.length == 0) {
                    this.equipmentQuantityInstallB4.plant_code.push('')
                    this.equipmentQuantityInstallB4.equipment_code.push('')
                    this.equipmentQuantityInstallB4.qty_installed.push('')
                    this.equipmentQuantityInstallB4.drawing_number.push('')
                } else if (this.equipmentQuantityInstallB4.plant_code.length > 0) {
                    if (this.equipmentQuantityInstallB4.plant_code[this.equipmentQuantityInstallB4.plant_code.length-1] != '' &&
                        this.equipmentQuantityInstallB4.equipment_code[this.equipmentQuantityInstallB4.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB4.qty_installed[this.equipmentQuantityInstallB4.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallB4.drawing_number[this.equipmentQuantityInstallB4.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallB4.plant_code.push('')
                        this.equipmentQuantityInstallB4.equipment_code.push('')
                        this.equipmentQuantityInstallB4.qty_installed.push('')
                        this.equipmentQuantityInstallB4.drawing_number.push('')
                        this.equipmentQuantityInstallB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiB4(key) {
                this.equipmentQuantityInstallB4.plant_code.splice(key, 1)
                this.equipmentQuantityInstallB4.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB4.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallB4.drawing_number.splice(key, 1)   
                this.equipmentQuantityInstallB4.hapus.splice(key, 1)
            },

            addPlantB4() {
                if (this.plantLevelB4.plant.length == 0) {
                    this.plantLevelB4.plant.push('')
                } else if (this.plantLevelB4.plant.length > 0) {
                    if (this.plantLevelB4.plant[this.plantLevelB4.plant.length-1] != '') {
                        this.plantLevelB4.plant.push('')
                        this.plantLevelB4.hapus.push('0')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlantB4(key) {
                this.plantLevelB4.plant.splice(key, 1)
                this.plantLevelB4.hapus.splice(key, 1)
                
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            
        }   
    };
</script>
<style></style>
